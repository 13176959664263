import { Col, Container, Row } from "react-bootstrap"
import React, { FC } from "react"
import { SanityButtonLink, SanityImage } from "@utils/globalTypes"

import BackgroundImage from "gatsby-background-image"
import FullyRoundedLightButton from "../Buttons/FullyRoundedLightButton"
import { GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { navigate } from "gatsby"
import * as s from "./TitleContainer.module.scss"

type Props = {
  title: string
  subtitle?: string
  image: SanityImage
  backgroundImage?: SanityImage
  cta?: SanityButtonLink
}

const TitleContainer: FC<Props> = ({
  title,
  subtitle,
  image,
  cta,
  backgroundImage,
}) => {
  const handleCtaClick = () => navigate(cta.link)

  const bgImage = convertToBgImage(backgroundImage?.asset?.gatsbyImageData)

  return (
    <BackgroundImage
      Tag={`section`}
      id={`media-test`}
      backgroundColor="black"
      {...bgImage}
      className={s.container}
    >
      <Container className={s.wrapper}>
        <Row noGutters className={s.row}>
          <Col xs={12} md={6}>
            <Container fluid>
              <Row noGutters>
                <Col xs={12}>
                  <h1 className={s.title}>{title}</h1>
                </Col>
                <Col xs={12}>
                  <p className={s.subtitle}>{subtitle}</p>
                </Col>
                {cta && cta?.label && (
                  <Col xs={12} className={s.ctaContainer}>
                    <FullyRoundedLightButton
                      style={{ width: 190 }}
                      onClick={handleCtaClick}
                    >
                      {cta.label}
                    </FullyRoundedLightButton>
                  </Col>
                )}
              </Row>
            </Container>
          </Col>
          <Col xs={12} md={6}>
            {image?.asset && (
              <GatsbyImage image={image.asset.gatsbyImageData} alt={title} />
            )}
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

export default TitleContainer
