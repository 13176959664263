import React, { FC } from "react"

import { Button } from "react-bootstrap"
import * as s from "./FullyRoundedLightButton.module.scss"

type Props = {
  onClick?: () => void
  style?: any
  type?: string
}
const FullyRoundedLightButton: FC<Props> = ({
  children,
  onClick = () => {},
  style = {},
  type = "button",
}) => {
  return (
    <Button type={type} style={style} onClick={onClick} className={s.button}>
      {children}
    </Button>
  )
}

export default FullyRoundedLightButton
